/* eslint-disable */
import Lightbulb from "../../../../assets/images/aboutUs/part-three-mobile.svg";
import { Typography, Button, Stack, Grid, Box, useMediaQuery, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function OurFocus() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container>
      <Grid
        paddingY={isMobile ? '20px' : 5}>
        <Grid
          container
          justifyContent={isMobile ? 'center' : 'space-between'}
          paddingX={isMobile ? '10px' : 2}
          paddingY={isMobile ? 10 : 5}
          alignItems="center"
          textAlign={isMobile ? 'center' : 'left'}
          borderRadius={isMobile ? 0 : '8px'}
          direction={isMobile ? 'column' : 'row'}
          sx={{ backgroundColor: `${isMobile ? '#fff' : '#f7f7f7'}` }}
        >
          <Grid item display={!isMobile && 'flex'} justifyContent={!isMobile && 'right'} order={isMobile ? 2 : 1} backgroundColor="#f7f7f7">
            <img
              src={Lightbulb}
              alt="bell"
              width={isMobile ? '100%' : '100%'}
              height={isMobile ? '100%' : '80%'}
            />
          </Grid>
          <Grid width={isMobile ? '100%' : '50%'} mt={isMobile && 4} order={isMobile ? 1 : 2} item>
            <Box alignItems={isMobile && 'center'} textAlign={isMobile ? 'left' : 'left'}>
              <Typography mb={2} lineHeight="40px" fontWeight={700} color="#1B8AED" fontSize={isMobile ? '24px' : '32px'}>
                Our Focus
              </Typography>
              <Stack spacing="30px" mt="20px" alignItems={isMobile && 'center'} mb='36px'>
                <Typography
                  lineHeight="30px"
                  fontWeight="400"
                  color="#3F3F3F"
                  fontSize={isMobile ? '16px' : '18px'}
                >
                  Semicolon Ventures is focused on addressing the problem of youth unemployment
                  by training potential entrepreneurs with the core skills they need to build
                  out tech based ventures. <br /> <br />
                  We then support these entrepreneurs to build and grow their
                  start-ups in the identified markets. We believe it is crucial to help birth
                  new companies that are able to create more jobs, and we provide all the support required to ensure this happens.
                </Typography>
                {isMobile && (
                  <Button
                    variant="contained"
                    fullWidth={isMobile && true}
                    sx={{
                      backgroundColor: '#1B8AED',
                      marginTop: '92px',
                      padding: '15px 20px',
                      fontSize: '14px',
                      fontWeight: '700',
                      width: `${!isMobile && '430px'}`,
                      height: `${isMobile ? '45px' : '48px'}`,
                      color: '#fff',
                      borderRadius: '7px',
                      textTransform: 'none',
                      '&:hover': { backgroundColor: '#1B8AED' }
                    }}
                    disableElevation
                  >
                    Learn More
                  </Button>
                )}
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Grid >
    </Container>
   
  );
}

export default OurFocus;
