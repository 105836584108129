import AboutTheFund from './componets/AboutTheFund';
import GetInTouch from './componets/GetInTouch';
import Hero from './componets/Hero';
import IndustrySegment from './componets/IndustrySegment';
import InvestmentStrategy from './componets/InvestmentStrategy';

function VentureFund() {
    return (
        <>
            <Hero />
            <AboutTheFund />
            <InvestmentStrategy />
            <IndustrySegment />
            <GetInTouch />
        </>
    );
}

export default VentureFund;
