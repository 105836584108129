import Hero from './components/Hero';
import NexusProject from './components/NexusProject';
import NexusVideo from './components/NexusVideo';
import Partners from './components/Partners';
import ReasonWhy from './components/ReasonWhy';

function Nexus() {
    return (
        <>
            <Hero />
            <NexusProject />
            <ReasonWhy />
            <NexusVideo />
            <Partners />
        </>
    );
}

export default Nexus;
