import Hero from './components/Hero';
import CTA from './components/CTA';
import CompanyHighlights from './components/CompanyHighlights';
// import PortfolioCompanies from './components/PortfolioCompanies';
import PortfolioConstruction from './components/PortfolioConstruction';

function Portfolio() {
    return (
        <>
            <Hero />
            <CTA />
            <CompanyHighlights />
            {/* <PortfolioCompanies /> */}
            <PortfolioConstruction />
        </>
    );
}

export default Portfolio;
