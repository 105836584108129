/* eslint-disable */
import { Grid, Typography, useMediaQuery, Box, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function Hero() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    return (
        <Box backgroundColor="#1B8AED;">
            <Container>
                <Grid
                    container
                    height="100vh"
                    textAlign="center"
                    direction={isMobile ? 'column' : 'row'}
                    alignItems="center"
                    pt={isMobile && 10}
                    justifyContent={isMobile ? 'center' : 'space-between'}
                >
                    <Grid
                        item
                        mt={isMobile ? '5%' : ''}
                        mb={isMobile ? '10%' : ''}
                        textAlign={isMobile ? 'center' : 'left'}
                        order={isMobile ? 2 : 1}
                    >
                        <Typography
                            fontWeight={isMobile ? 600 : 700}
                            fontSize={isMobile ? 32 : '44px'}
                            lineHeight={isMobile ? '' : '68px'}
                            color="#fff"
                            textAlign={isMobile ? 'center' : 'left'}
                            letterSpacing={isMobile ? '' : '-2px'}
                            width={isMobile ? '265px' : '435px'}
                        >
                            Creating prosperity through innovation and tech-enabled businesses
                        </Typography>
                    </Grid>
                    <Grid mt={isMobile ? '2%' : ''} display={!isMobile && 'flex'} justifyContent={!isMobile && 'right'} item order={isMobile ? 1 : 2}>
                        <img
                            src="https://res.cloudinary.com/omimi/image/upload/v1657813991/semicolon%20ventures/africa_my5wc5.png"
                            alt="growth plant"
                            width={isMobile ? '100%' : '80%'}
                            height={isMobile ? '100%' : '80%'}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>

    );
}

export default Hero;
