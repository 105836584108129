/* eslint-disable */
import { Typography, Stack, Box, useMediaQuery, Container, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function VentureFund() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
   

    return (
        <Box
            sx={{ background: `${!isMobile && 'linear-gradient(1.57deg, #97C9F7 -223.24%, #FFFFFF 100.83%), #FFFFFF;'}` }}
        >
            <Container>
                <Stack
                    // sx={{ background: `${!isMobile && 'linear-gradient(1.57deg, #97C9F7 -223.24%, #FFFFFF 100.83%), #FFFFFF;'}` }}
                    direction={isMobile ? 'column' : 'row'}
                    marginY={isMobile ? '10px' : 20}
                    paddingY={isMobile ? '10px' : 20}
                    alignItems="center"
                    mt={isMobile ? '60px' : 20}
                    justifyContent="space-between"
                >
                    <Box>
                        <img
                            src="https://res.cloudinary.com/omimi/image/upload/v1656069285/Ellipse_117_dxtxcx.png"
                            alt="growth plant"
                            width={isMobile ? '100%' : '400px'}
                            height={isMobile ? '100%' : '400px'}
                        />
                    </Box>
                    <Stack direction="column" mt={isMobile && '60px'} alignItems={isMobile ? 'center' : 'left'}>
                        <Typography lineHeight="40px" fontWeight={700} color="#101828" fontSize={32}>
                            Venture Fund
                        </Typography>
                        <Box mt="13px">
                            <img
                                src="https://res.cloudinary.com/omimi/image/upload/v1655071886/semicolon%20ventures/underlineVector_zdsoej.svg"
                                alt="underline"
                            // width={isMobile ? '100px' : '500px'}
                            />
                        </Box>
                        <Typography
                            my="19px"
                            width={isMobile ? '325px' : '422px'}
                            lineHeight="30px"
                            fontWeight={400}
                            color="#000"
                            fontSize={isMobile ? '14px' : '18px'}
                            textAlign={isMobile ? 'center' : 'left'}
                        >
                            Semicolon Ventures backs ambitious entrepreneurs solving local and global problems in innovative ways, while creating
                            increased economic inclusion and employment opportunities resulting in economic growth.
                        </Typography>
                        <Button
                            variant="contained"
                            fullWidth={isMobile && true}
                            sx={{
                                backgroundColor: '#1B8AED',
                                marginTop: '44px',
                                padding: '15px 32px',
                                fontSize: '14px',
                                fontWeight: '700',
                                width: `${!isMobile && '148px'}`,
                                height: `${isMobile ? '45px' : '48px'}`,
                                color: '#fff',
                                borderRadius: '7px',
                                textTransform: 'none',
                                '&:hover': { backgroundColor: '#1B8AED' }
                            }}
                            disableElevation
                        >
                            <Typography fontSize="16px" color="#FFFFFF" lineHeight="24px" fontWeight={700}>
                                Learn More
                            </Typography>
                        </Button>
                    </Stack>
                </Stack>
            </Container>
        </Box>

    );
}

export default VentureFund;
