/* eslint-disable */
import { Box, Grid, Stack, Typography, useMediaQuery, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const industrySegments = [
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1656498771/semicolon%20ventures/Group_48096788_vgqbzk.png',
        title: 'FinTech',
        caption: 'Technology used to help companies and consumers manage their finances',
        color: 'rgba(27, 138, 237, 1)',
        backgroundColor: 'rgba(27, 138, 237, 0.02)'
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1656498771/semicolon%20ventures/Group_48096790_fr2fpv.png',
        title: 'Tourism',
        caption: 'Technology applied to support and improve how people explore and move from place to place',
        color: 'rgba(237, 27, 65, 1)',
        backgroundColor: 'rgba(237, 27, 65, 0.04)'
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1656498771/semicolon%20ventures/Group_48096793_jargxt.png',
        title: 'CivicTech',
        caption: 'Technology used to educate and empower citizens of their rights and role in nation building',
        color: 'rgba(192, 146, 35, 1)',
        backgroundColor: 'rgba(192, 146, 35, 0.04)'
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1656498771/semicolon%20ventures/Group_48096789_xpuksn.png',
        title: 'AgricTech',
        caption: 'Technology applied to agriculture to increase yield, effiiciency and profitability of agro produce',
        color: 'rgba(255, 142, 0, 1)',
        backgroundColor: 'rgba(255, 142, 0, 0.02)'
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657802102/semicolon%20ventures/Group_48096878_cdodpb.png',
        title: 'Mobility',
        caption: 'Technology applied to mobility increases efficiency and improves customer experience',
        color: 'rgba(51, 51, 51, 1)',
        backgroundColor: 'rgba(51, 51, 51, 0.05)'
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1656498771/semicolon%20ventures/Group_48096791_uarkfs.png',
        title: 'Digital Health',
        caption: 'Technology applied to improve, support and promote better and healthy living',
        color: 'rgba(21, 148, 0, 1)',
        backgroundColor: 'rgba(21, 148, 0, 0.04)'
    }
];

function IndustrySegment() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
   

    return (
        <Box paddingY={isMobile ? '10px' : 10} sx={{ backgroundColor: '#F6F6F6' }}>
            <Container>
                <Grid
                    container
                    my={isMobile && 20}
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    gap={4}
                >
                    <Grid item textAlign="center">
                        <Stack direction="column" alignItems="center" textAlign="center" justifyContent="center">
                            <Typography lineHeight="40px" letterSpacing="-4" fontWeight={700} color="#101828" fontSize="32px">
                                Our Industry Segment
                            </Typography>
                            <Box>
                                <img
                                    src="https://res.cloudinary.com/omimi/image/upload/v1655071886/semicolon%20ventures/underlineVector_zdsoej.svg"
                                    alt="underline"
                                />
                            </Box>
                            <Typography
                                my="20px"
                                width={isMobile ? '200px' : '478px'}
                                lineHeight="30px"
                                fontWeight={400}
                                color="#000"
                                fontSize={isMobile ? '14px' : '20px'}
                            >
                                With a varied portfolio of companies, we are cut accross several industries
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={isMobile ? '10px' : 10} justifyContent="center" alignItems="top">
                            {industrySegments.map((value, index) => (
                                <Grid key={index} item lg={4} xl={4} md={4} xs={12} sm={12}>
                                    <Stack
                                        backgroundColor={value.backgroundColor}
                                        borderRadius="12px"
                                        height="250px"
                                        justifyContent="flex-start"
                                        py="30px"
                                        px={isMobile ? '5px' : '20px'}
                                        alignItems="center"
                                        textAlign="center"
                                    >
                                        <img src={value.icon} alt="" width="70px" height="70px" />
                                        <Typography mt="34px" fontWeight={600} fontSize={20} color={value.color}>
                                            {value.title}
                                        </Typography>
                                        <Typography mt="20px" fontWeight={400} fontSize="16px" lineHeight="21px" color="#101828">
                                            {value.caption}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default IndustrySegment;
