import Hero from './componets/Hero';
import DirectImpact from './componets/DirectImpact';
import InDirectImpact from './componets/InDirectImpact';
import SystemChangeImpact from './componets/SystemChangeImpact';
import ImpactStories from './componets/ImpactStories';

function Impact() {
    return (
        <div>
            <Hero />
            <DirectImpact />
            <InDirectImpact />
            <SystemChangeImpact />
            <ImpactStories />
        </div>
    );
}

export default Impact;
