const content = [
    {
        title: 'Build Ventures',
        image: 'https://res.cloudinary.com/omimi/image/upload/v1656325910/semicolon%20ventures/Frame_48097188_nlodks.png',
        description: `We utilize our vast array of tech talent and our experience as entrepreneurs to deploy resources aimed at identifying and co-building innovative tech start-ups.`
    },
    {
        title: 'Launch',
        image: 'https://res.cloudinary.com/omimi/image/upload/v1656325911/semicolon%20ventures/Frame_48097188_1_thrlzs.png',
        description: `By utilizing our expertise in developing and launching a successful ed-tech business on the continent, to grow and develop our portfolio companies.`
    },
    {
        title: 'Funding',
        image: 'https://res.cloudinary.com/omimi/image/upload/v1656325910/semicolon%20ventures/Frame_48097188_2_nhcm78.png',
        description: `We leverage our network of angel investors and venture capital funds to raise Seed funding to support and develop our portfolio companies.`
    },
    {
        title: 'Co-Innovation',
        image: 'https://res.cloudinary.com/omimi/image/upload/v1656325910/semicolon%20ventures/Frame_48097188_3_cmipff.png',
        description: `Working closely with our our Coperate partners to build and develop innovative technology solutions that tackle their industry specific problems.`
    },
    {
        title: 'Nurture Team',
        image: 'https://res.cloudinary.com/omimi/image/upload/v1656325911/semicolon%20ventures/Frame_48097188_4_kwc2lu.png',
        description: `Our nurtured teams comprising of talented individuals by providing resources, business strategy and organizational support to help these teams reach their ground-braking potential.`
    }
];

export default content;
