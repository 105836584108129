import Hero from './components/Hero';
import WhoWeAre from './components/WhoWeAre';
import OurFocus from './components/OurFocus';
import ProgramTabs from './components/ProgramTabs';
import BoardOfAdvisors from './components/BoardOfAdvisors';

function About() {
    return (
        <div>
            <Hero />
            <WhoWeAre />
            <OurFocus />
            <ProgramTabs />
            <BoardOfAdvisors />
        </div>
    );
}

export default About;
