import * as React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, Select, FormControl, MenuItem, Typography, Box, Grid, useMediaQuery } from '@mui/material';
import StartupContent from './StartupContent';
import content from './content';
import { makeStyles } from '@mui/styles';
import sun from '../../../../assets/icons/Sun.svg';
import Funding from '../../../../assets/icons/Funding.svg';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(() => ({
    tabs: {
        '& .MuiButtonBase-root.MuiTab-root': {
            alignItems: 'center',
            justifyContent: 'center',
            width: '295px',
            fontWeight: '700',
            fontSize: '32px',
            borderRadius: '8px',
            height: '50px',
            padding: '12px, 30px',
            textTransform: 'none'
        },
        '& 	.Mui-selected': {},
        '& 	.MuiSelect-select': {
            width: '295px',
            height: '40px',
            borderRadius: '2px',
            paddingX: '30px',
            paddingY: '10px',
            borderBottom: '2.5px solid #1B8AED',
            fontWeight: 700,
            textAlign: 'center',
            color: '#1B8AED'
        }
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

function BasicTabs() {
    const [value, setValue] = React.useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const handleChange = (event, newValue) => {
        setValue(isMobile ? event.target.value : newValue);
    };

    const classes = useStyles();
    return (
        <Grid my="5%" container alignItems="center" justifyContent="center" height="80vh" width="100%">
            <Grid item textAlign="center">
                {isMobile ? (
                    <Box>
                        <FormControl fullWidth>
                            <Select
                                variant="standard"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={value}
                                onChange={handleChange}
                                className={classes.tabs}
                            >
                                <MenuItem value={0}>Nexus Training</MenuItem>
                                <MenuItem value={1}>Startup Studio</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                ) : (
                    <Tabs
                        className={classes.tabs}
                        orientation="horizontal"
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        sx={{ borderBottom: 1, borderColor: 'divider' }}
                    >
                        <Tab label="Nexus Training" {...a11yProps(0)} />
                        <Tab label="Startup Studio" {...a11yProps(1)} />
                    </Tabs>
                )}
            </Grid>
            <Grid sx={{ backgroundColor: '#F6F6F6' }} item lg={12} xl={12} sm={12} md={12} xs={12}>
                <TabPanel value={value} index={0}>
                    <StartupContent
                        icon={sun}
                        descHeader={content[0].heading}
                        illustration={content[0].img}
                        desc={content[0].description}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <StartupContent
                        icon={Funding}
                        descHeader={content[1].heading}
                        illustration={content[1].img}
                        desc={content[1].description}
                    />
                </TabPanel>
            </Grid>
        </Grid>
    );
}
BasicTabs.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.object
};
export default BasicTabs;
