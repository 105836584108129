/* eslint-disable */
import Hero from './components/Hero';
import Process from './components/Process';
import ServicesProvided from './components/ServicesProvided';
import ServicesLnR from './components/ServicesLnR';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';

function Services() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const nexusVideo = (
        <iframe
            width={isMobile ? '100%' : '500px'}
            height={isMobile ? '100%' : '400px'}
            src="https://www.youtube.com/embed/tt70EfZTnxI?modestbranding=1&showinfo=0&end=280&rel=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe>
    );

    const services = [
        {
            title: 'GrowthLab',
            image: 'https://res.cloudinary.com/omimi/image/upload/v1657722070/semicolon%20ventures/Frame_48097269_jkpinu.png',
            description: `We focus on co-building and developing start-ups swiftly and efficiently, by accepting founders who have illustrated commitment and vision, and have the skill-set
         to develop and run technology-enabled businesses.`,
            description1: ` We offer early-stage business support geared at enabling the company launch
        (typically within 6 months) a viable product capable of reaching market fit, scaling and ultimately achieving a profitable exit.`,
            color: 'rgba(255, 65, 51, 0.03)',
            button: false,
            position: 'right'
        },
        {
            title: 'The Venture Fund',
            image: 'https://res.cloudinary.com/omimi/image/upload/v1656069285/Ellipse_117_dxtxcx.png',
            description: `The Growth fund is set up to deploy pre-seed stage capital to support start-ups in our portfolio. The aim of the fund is to address the lack of access to early-stage funding for startups on the continent. The funds will free-up founders to build quickly and engage external support services when needed.`,
            color: '#FFF',
            button: false,
            position: 'left'
        },
        {
            title: 'Nexus Training',
            image: nexusVideo,
            description: `While building their product, the founders participate in the Nexus program, a 6-month business management course delivered by Henley Business School and Lagos Business School, aimed at providing a “mini-MBA” and mentorship for entrepreneurs.`,
            color: 'rgba(255, 167, 0, 0.01)',
            button: false,
            position: 'right'
        },
        {
            title: 'Startup Studio',
            image: 'https://res.cloudinary.com/omimi/image/upload/v1657722717/semicolon%20ventures/Group_48096871_qjgdop.png',
            description: `The Studio serves as a 3-month immersive workshop program which gives entrepreneurs the opportunity to develop their ideas, gather the necessary skills to scale and develop their Minimum Viable Product (MVP).`,
            description1: `We combine Design Thinking, Lean Start-up and Agile methodologies in identifying the real customer problems and developing the right products based on a sound business model. We help the founders structure all necessary agreements, and other administrative tasks such as setting up accounts and registering their companies.`,
            color: '#fff',
            button: false,
            position: 'left'
        },
        {
            title: 'Open Innovation',
            image: 'https://res.cloudinary.com/omimi/image/upload/v1657722835/semicolon%20ventures/streamlinehq-solving-problem-3-product-400_1_v4xoij.png',
            description: `Semicolon operates a corporate innovation model that enables companies engage early with start-ups or have teams work on business challenges of interest without significant upfront costs.`,
            description1: ` We partner with corporate organizations and achieve this through: research, product development and project implementation, focusing on developing innovative product solutions for our corporate partners.`,
            color: 'rgba(27, 138, 237, 0.03)',
            button: true,
            position: 'right'
        }
    ];
    return (
        <>
            <Hero />
            {services.map((service, index) => (
                <Grid sx={{ backgroundColor: service.color }} key={index}>
                    <ServicesLnR
                        title={service.title}
                        color={service.color}
                        position={service.position}
                        description={service.description}
                        description1={service.description1}
                        button={service.button}
                        image={service.image}
                    />
                </Grid>
            ))}
            <ServicesProvided />
            <Process />
        </>
    );
}
export default Services;
