/* eslint-disable */
import { Grid, Typography, useMediaQuery, Box, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function Hero() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
   

    return (
        <Box backgroundColor="#F4F4F4">
            <Container>
                <Grid
                    container
                    height="100vh"
                    textAlign="center"
                    direction={isMobile ? 'column' : 'row'}
                    alignItems="center"
                    pt={isMobile && 10}
                    justifyContent={isMobile ? 'center' : 'space-between'}
                >
                    <Grid
                        item
                        mt={isMobile ? '5%' : ''}
                        mb={isMobile ? '10%' : ''}
                        textAlign={isMobile ? 'center' : 'left'}
                        order={isMobile ? 2 : 1}
                    >
                        <Typography
                            fontWeight={isMobile ? 600 : 700}
                            fontSize={isMobile ? 32 : 60}
                            lineHeight={isMobile ? '' : '78px'}
                            color="#1B8AED"
                            textAlign={isMobile ? 'center' : 'left'}
                            letterSpacing={isMobile ? '' : '-4px'}
                            width={isMobile ? '265px' : '435px'}
                        >
                            The Venture Fund
                        </Typography>
                        <Typography
                            fontSize={isMobile ? '16px' : '24px'}
                            mt="20px"
                            lineHeight={isMobile ? '' : '30px'}
                            fontWeight={400}
                            color="#475661"
                            textAlign={isMobile ? 'center' : 'left'}
                            maxWidth={isMobile ? '265px' : '435px'}
                        >
                            Addressing the lack of access to early-stage funding for startups on the continent.
                        </Typography>
                    </Grid>
                    <Grid mt={isMobile ? '2%' : ''} display={!isMobile && 'flex'} justifyContent={!isMobile && 'right'} item order={isMobile ? 1 : 2}>
                        <img
                            src="https://res.cloudinary.com/omimi/image/upload/v1656069285/Ellipse_117_dxtxcx.png"
                            alt="growth plant"
                            width={isMobile ? '100%' : '80%'}
                            height={isMobile ? '100%' : '80%'}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>

    );
}

export default Hero;
