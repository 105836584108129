/* eslint-disable */
import AboutUsIdea from 'assets/images/aboutUs/about-hero-image.svg';
import AboutUsIdea1 from 'assets/images/aboutUs/about-hero-mobile.svg';
import LFTpattern from 'assets/illustrations/line-pattern.svg';
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Typography, useMediaQuery, Container } from '@mui/material';

function Hero() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    return (
        <Grid backgroundColor="#F4F4F4">
            <Container>
                <Grid
                    container
                    position="relative"
                    height="100vh"
                    textAlign="center"
                    alignItems="center"
                    direction={isMobile ? 'column' : 'row'}
                    justifyContent={isMobile ? 'center' : 'space-between'}
                >
                    <Grid
                        item
                        mt={isMobile ? '5%' : '100px'}
                        mb={isMobile ? '10%' : ''}
                        textAlign={isMobile ? 'center' : 'left'}
                        order={isMobile ? 2 : 1}
                    >
                        <Typography
                            fontWeight={isMobile ? 600 : 700}
                            fontSize={isMobile ? 32 : 60}
                            lineHeight={isMobile ? '' : '78px'}
                            color="#101828"
                            textAlign={isMobile ? 'center' : 'left'}
                            letterSpacing={isMobile ? '' : '-4px'}
                            width={isMobile ? '265px' : '350px'}
                        >
                            Semicolon for
                        </Typography>
                        <Typography
                            fontWeight={isMobile ? 600 : 700}
                            fontSize={isMobile ? 32 : 60}
                            lineHeight={isMobile ? '' : '78px'}
                            textAlign={isMobile ? 'center' : 'left'}
                            letterSpacing={isMobile ? '' : '-4px'}
                            width={isMobile ? '265px' : ''}
                            color="#1B8AED"
                        >
                            Innovators
                        </Typography>
                    </Grid>
                    <Grid mt={isMobile ? '10%' : ''} display={!isMobile && 'flex'} justifyContent={!isMobile && 'right'} item order={isMobile ? 1 : 2}>
                        <img
                            src={isMobile ? AboutUsIdea1 : 'https://res.cloudinary.com/omimi/image/upload/v1657813331/semicolon%20ventures/unsplash__gEKtyIbRSM_md6bxx.png'}
                            alt=""
                            width={isMobile ? '100%' : '80%'}
                            height={isMobile ? '100%' : '80%'}
                        />
                    </Grid>
                    {!isMobile && (
                        <Box position="absolute" top="0.2%" right="0.1%" display="flex" justifyContent="right">
                            <img src={LFTpattern} alt="" width="50%"/>
                        </Box>
                    )}
                </Grid>
            </Container>
        </Grid>
    );
}

export default Hero;
