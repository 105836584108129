import nexusTraining from '../../../../assets/images/aboutUs/nexusTraining.svg';
import startupClinic from '../../../../assets/images/aboutUs/startupStudio.svg';

const content = [
    {
        img: nexusTraining,
        heading: 'Nexus Training',
        description:
            'We are focused on addressing the problem of youth unemployment by training potential entrepreneurs' +
            'with the core skills they need to build out tech based ventures, and then supporting these entrepreneurs' +
            'to build and grow their start-ups in the identified markets.'
    },
    {
        img: startupClinic,
        heading: 'Startup Studio',
        description:
            'We are focused on addressing the problem of youth unemployment by training potential entrepreneurs with' +
            'the core skills they need to build out tech based ventures, and then supporting these entrepreneurs to build' +
            'and grow their startups in the identified markets.'
    }
];
export default content;
