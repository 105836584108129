/* eslint-disable */
import { Grid, Typography, Stack, Box, useMediaQuery, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StartupCard from './StartupCard';
import SwipeableMobileCarousel from './mobileCarousel';
import content from './content';

function Hero() {
    const startupdata = content;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    return (
        <Container>
            <Grid
                sx={{ backgroundColor: 'linear-gradient(180deg, #F1F8FE 0%, rgba(255, 255, 255, 0) 7.18%' }}
                container
                mt={isMobile ? 10 : 20}
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                minHeight="80vh"
                direction="column"
            >
                <Grid item mb={10}>
                    <Stack textAlign="center" alignItems="center" justifyContent="center">
                        <img
                            src="https://res.cloudinary.com/omimi/image/upload/v1655128241/founder_heading_jmapoq.svg"
                            width={isMobile ? '80%' : ''}
                            alt="underline"
                        />
                        <Typography mt={5} fontWeight="400" maxWidth={isMobile ? '80vw' : '50%'} fontSize={isMobile ? '16px' : '18px'} height="90px" color="#667085">
                            We invest in founders who are ambitious entrepreneurs, building companies that solve local and global
                            problems in innovative ways, while creating increased economic inclusion and employment opportunities
                            resulting in economic growth.
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item>
                    {isMobile ? (
                        <SwipeableMobileCarousel />
                    ) : (
                        <Stack
                            direction={isMobile ? 'column' : 'row'}
                            // px={isMobile ? '20px' : 20}
                            spacing={10}
                                alignItems={isMobile ? 'center' : 'top'}
                            justifyContent="center"
                        >
                            {startupdata.map((startup, index) => (
                                <StartupCard key={index} title={startup.title} description={startup.description} image={startup.image} />
                            ))}
                        </Stack>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
}

export default Hero;
