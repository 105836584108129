/* eslint-disable */
import { Box, Grid, Stack, Typography, useMediaQuery, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useState } from 'react';
import StartupCard from 'views/pages/Portfolio/components/StartupCard';
import SwipeableMobileCarousel from 'views/pages/Portfolio/components/mobileCarousel';
import content from 'views/pages/Portfolio/components/content';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ImpactStoriess = [
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657821455/semicolon%20ventures/Featured_icon_6_djx1oc.png',
        title: 'Support for all',
        caption: 'Support people without backgrounds of privilege and access to create solutions',
        color: '#009FF5',
        backgroundColor: 'rgba(0, 159, 245, 0.05)',
        captionColor: 'rgba(0, 159, 245, 0.72)'
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657821455/semicolon%20ventures/Featured_icon_7_c8yy1i.png',
        title: 'Problem solving',
        caption: 'Democratize problem solving and who can be a successful founder',
        color: '#F75500',
        backgroundColor: 'rgba(247, 85, 0, 0.05)',
        captionColor: 'rgba(247, 85, 0, 0.72)'
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657821455/semicolon%20ventures/Featured_icon_8_eqjcjz.png',
        title: 'Efficient building',
        caption: 'Build more efficiency into the early stage angel and VC market',
        color: '#00D689',
        backgroundColor: 'rgba(0, 214, 137, 0.05)',
        captionColor: 'rgba(0, 214, 137, 0.72)'
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657821455/semicolon%20ventures/Featured_icon_9_pioze2.png',
        title: 'Skill provision',
        caption: 'Ventures are oriented to solve real problems, equipped with the right skills and given the critical early stage support',
        color: '#2A2B4B',
        backgroundColor: 'rgba(55, 53, 91, 0.05)',
        captionColor: 'rgba(59, 57, 95, 0.72)'
    }
];

function ImpactStories() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const contents = ImpactStoriess;
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = contents.length;
    const startupdata = content;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Box paddingY={isMobile ? '10px' : 10} sx={{ backgroundColor: '#F6F6F6' }}>
            <Container>
                <Grid
                    container
                    my={isMobile && 5}
                    justifyContent="center"
                    alignItems={isMobile ? 'center' : 'left'}
                    direction="column"
                    gap={8}
                >
                    <Grid item textAlign={isMobile ? 'center' : 'left'} maxWidth={isMobile ? '320px' : '100%'}>
                        <Typography lineHeight="40px" letterSpacing="-4" fontWeight={700} color="#101828" fontSize={isMobile ? '24px' : '32px'}>
                            Impact Stories
                        </Typography>
                    </Grid>
                    <Grid item>
                        {isMobile ? (
                            <SwipeableMobileCarousel />
                        ) : (
                            <Stack
                                direction={isMobile ? 'column' : 'row'}
                                // px={isMobile ? '20px' : 20}
                                spacing={10}
                                alignItems={isMobile ? 'center' : 'top'}
                                justifyContent="center"
                            >
                                {startupdata.map((startup, index) => (
                                    <StartupCard key={index} title={startup.title} description={startup.description} image={startup.image} />
                                ))}
                            </Stack>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default ImpactStories;
