/* eslint-disable */
import { Grid, Typography, Button, Stack, Box, useMediaQuery, Container } from '@mui/material';
import Mungin from 'assets/icons/mungin.svg';
import Slo from 'assets/icons/slo.svg';
import Onhova from 'assets/icons/onhova.svg';
import OnePlug from 'assets/icons/onePlug.svg';
import Irespond from 'assets/icons/iRspond.svg';
import GoodTime from 'assets/icons/goodtime.svg';
import RoadPadi from 'assets/icons/roadPadi.svg';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router';

function Portfolio() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    return (
        <Container>
            <Grid
                sx={{ minHeight: '800px' }}
                mt={isMobile && '45px'}
                container
                justifyContent="center"
            >
                <Grid item lg={12} xl={12} xs={12} sm={12}>
                    <Stack direction="column" mt="20px" alignItems="center" textAlign="center" justifyContent="center">
                        <Typography lineHeight="40px" fontWeight={700} color="#101828" fontSize={32}>
                            Our Portfolio
                        </Typography>
                        <Box mt="13px">
                            <img
                                src="https://res.cloudinary.com/omimi/image/upload/v1655071886/semicolon%20ventures/underlineVector_zdsoej.svg"
                                alt="underline"
                            />
                        </Box>
                        <Typography
                            my="19px"
                            width={isMobile ? '325px' : '747px'}
                            lineHeight="30px"
                            fontWeight={400}
                            color="#000"
                            fontSize={isMobile ? '14px' : '20px'}
                        >
                            Semicolon Ventures backs ambitious entrepreneurs solving local and global problems in innovative ways, while
                            creating increased economic inclusion and employment opportunities resulting in economic growth.
                        </Typography>
                        <Stack direction={isMobile ? 'column' : 'row'} alignItems="center" spacing={6} justifyContent="space-evenly">
                            <img src={Mungin} alt="mungin" width="120px" height="34px" />
                            <img src={Slo} alt="slo" />
                            <img src={Onhova} alt="onhova" />
                            <img src={RoadPadi} alt="roadpadi" width="120px" height="34px" />
                        </Stack>
                        <Stack direction={isMobile ? 'column' : 'row'} mt={6} alignItems="center" spacing={6} justifyContent="space-evenly">
                            <img src={GoodTime} alt="gt" />
                            <img src={Irespond} alt="ir" />
                            <img src={OnePlug} alt="oneP" />
                        </Stack>
                        <Button
                            variant="contained"
                            fullWidth={isMobile && true}
                            onClick={()=>{navigate('/portfolio')}}
                            sx={{
                                backgroundColor: '#1B8AED',
                                marginTop: '92px',
                                padding: '15px 20px',
                                fontSize: '14px',
                                fontWeight: '700',
                                width: `${!isMobile && '430px'}`,
                                height: `${isMobile ? '45px' : '48px'}`,
                                color: '#fff',
                                borderRadius: '7px',
                                textTransform: 'none',
                                '&:hover': { backgroundColor: '#1B8AED' }
                            }}
                            disableElevation
                        >
                            <Typography fontSize="16px" color="#FFFFFF" lineHeight="24px" fontWeight={700}>
                                View Portfolio
                            </Typography>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
            </Container>
       
    );
}

export default Portfolio;
