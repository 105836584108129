import Hero from './components/Hero';
import WhoWeAre from './components/WhoWeAre';
import WhatWeDo from './components/WhatWeDo';
import Portfolio from './components/Portfolio';
import VentureFund from './components/VentureFund';

function Home() {
    return (
        <>
            <Hero />
            <WhoWeAre />
            <WhatWeDo />
            <VentureFund />
            <Portfolio />
        </>
    );
}

export default Home;
