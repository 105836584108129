/* eslint-disable */
import { Typography, Stack, Box, useMediaQuery, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import content from './content';
import SwipeableMobileCarousel from './mobileCarousel';
import ActivityCard from './WhatWeDoCard';

function WhatWeDo() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
   

    const activity = content;

    return (
        <Container>
            <Stack
                direction="column"
                alignItems="center"
                textAlign="center"
                justifyContent="center"
                mt={!isMobile && '80px'}
            >
                <Typography lineHeight="40px" fontWeight={700} color="#101828" fontSize={32}>
                    What we do
                </Typography>
                <Box mt="13px" mb={isMobile ? '32px' : '47px'}>
                    <img
                        src="https://res.cloudinary.com/omimi/image/upload/v1655071886/semicolon%20ventures/underlineVector_zdsoej.svg"
                        alt="underline"
                    />
                </Box>
                {isMobile ? (
                    <SwipeableMobileCarousel />
                ) : (
                    <Stack gap={10}>
                        <Stack
                            direction="row"
                            gap={10}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <ActivityCard title={activity[0].title} description={activity[0].description} image={activity[0].image} />
                            <ActivityCard title={activity[1].title} description={activity[1].description} image={activity[1].image} />
                            <ActivityCard title={activity[2].title} description={activity[2].description} image={activity[2].image} />
                        </Stack>
                        <Stack
                            direction="row"
                            gap={10}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <ActivityCard title={activity[3].title} description={activity[3].description} image={activity[3].image} />
                            <ActivityCard title={activity[4].title} description={activity[4].description} image={activity[4].image} />
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </Container>
    );
}

export default WhatWeDo;
