import { Link } from 'react-router-dom';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

function HeaderLink({ page, selected, title, onclick }) {
    // let className = selected ? 'headerlink-no-link ' : '';
    const className = 'headerlink-title';
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Box
            sx={{
                borderBottom: `3px solid ${selected ? '#1B8AED' : '#fff'}`,
                opacity: 1,
                // transition: 'opacity 200ms linear',
                paddingBottom: '3px'
            }}
        >
            <Link to={`/${page}`} onClick={onclick} className={className}>
                <Typography
                    fontSize="14px"
                    lineHeight="14px"
                    fontWeight={selected ? '700' : '400'}
                    textAlign={isMobile ? 'left' : 'center'}
                >
                    {title}
                </Typography>
            </Link>
        </Box>
    );
}
HeaderLink.propTypes = {
    page: PropTypes.any,
    selected: PropTypes.bool,
    title: PropTypes.string,
    onclick: PropTypes.func
};
export default HeaderLink;
