import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/header/Header';
import Home from 'views/pages/Home/Home';
import About from 'views/pages/About us/About';
import Service from 'views/pages/Services/Services';
import Portfolio from 'views/pages/Portfolio/Portfolio';
import Footer from 'components/footer/Footer';
import VentureFund from 'views/pages/Venture fund/VentureFund';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Impact from 'views/pages/Our Impact/Impact';
import ScrollToTop from 'ScrollToTop';
import Nexus from 'views/pages/Nuexus/Nexus';

const baseTheme = createTheme({
    typography: {
        fontFamily: ['IBM Plex Sans', 'Roboto', 'sans-serif', 'cursive'].join(',')
    }
});

function App() {
    return (
        <ThemeProvider theme={baseTheme}>
            <Router>
                <Header />
                <ScrollToTop>
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="*" element={<Home />} />
                        <Route exact path="/our-impact" element={<Impact />} />
                        <Route path="/our-services" element={<Service />} />
                        <Route path="/portfolio" element={<Portfolio />} />
                        <Route path="/venture-fund" element={<VentureFund />} />
                        <Route path="/nexus" element={<Nexus />} />
                        <Route path="/about" element={<About />} />
                    </Routes>
                </ScrollToTop>
                <Footer />
            </Router>
        </ThemeProvider>
    );
}

export default App;
