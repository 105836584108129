import { Box, Grid, Typography, Stack, useMediaQuery, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SwipeableMobileCarousel from './mobileCarouselCompayHighlight';

const industrySegments = [
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657490319/semicolon%20ventures/rpdi_jbe8vk.png',
        caption: `A smart web and mobile travel platform for transport service
providers and travellers, that allows users book their travel online
to anywhere, with any transporter, enabling efficient movement of
goods and people at any time.`
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657490319/semicolon%20ventures/ires_qcxbkg.png',
        caption: `iRespond is an efficient mental health care platform that provides
personalized therapy through AI-based self services,
e-consultation, Employee Assistance Program as well as advocacy
through campaigns.`
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657490319/semicolon%20ventures/Frame_48097077_grmzx6.png',
        caption: `Mungin is an agricultural data platform that provides
agricultural insights using intelligent data collection and
collation techniques with the aim of improving processes,productivity and contribution to the agricultural ecosystem.`
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657490319/semicolon%20ventures/Frame_48097077_2_czwupi.png',
        caption: `Onhova is focusing on how to enhance the wholesale andretail distribution network through its digital procurement
platform which enables price discovery, inventory visibility
and seamless stock replenishment.`
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657490319/semicolon%20ventures/Frame_48097077_3_gbutl5.png',
        caption: `Oneplug is building financial integration infrastructure,
implementing channels available through the advent of open
banking to enable interaction and visibility across the financial
services industry.`
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657490319/semicolon%20ventures/Frame_48097077_4_e9qamr.png',
        caption: `Tour Bounty is an integrated social technology platform thatcurates,collates and displays tourism experiences. By beginning in
Africa, Tour Bounty will preserve cultural heritage across the
continent.`
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657490319/semicolon%20ventures/Frame_48097077_5_q6xbbq.png',
        caption: `Goodtime is a community curated for users and businesses that
match-makes people based on their recreational and social
interest. Goodtime aims toaddress the lack of interconnectivity
between users, recreational activities and needs`
    }
];

function CompanyHighlights() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                backgroundColor: '#1E323F'
            }}
        >
            <Container>
                <Grid
                    container
                    direction="column"
                    my={isMobile && 20}
                    paddingY={isMobile ? '10px' : 10}
                    justifyContent="center"
                    gap={4}
                    minHeight={600}
                >
                    <Grid item textAlign="center">
                        <Stack direction="column" alignItems="center" textAlign="center" justifyContent="center">
                            <Typography lineHeight="40px" letterSpacing="-4" fontWeight={700} color="#fff" fontSize="32px">
                                Company highlights
                            </Typography>
                            <Box>
                                <img
                                    src="https://res.cloudinary.com/omimi/image/upload/v1655118776/blue-underline-Vector_fvdqoo.svg"
                                    alt="underline"
                                />
                            </Box>
                        </Stack>
                    </Grid>

                    {isMobile ? (
                        <SwipeableMobileCarousel />
                    ) : (
                        <Grid
                            item
                            className="scroller"
                            container
                            display="flex"
                            maxWidth="80vw"
                            flexDirection="row"
                            gap={2}
                            sx={{
                                flexWrap: 'nowrap',
                                overflowY: 'hidden',
                                overflowX: 'auto',
                                '&::-webkit-scrollbar': { display: 'none' }
                            }}
                            justifyContent="left"
                            alignItems="stretch"
                        >
                            {industrySegments.map((value, index) => (
                                <Grid
                                    item
                                    key={index}
                                    backgroundColor="#fff"
                                    borderRadius="12px"
                                    maxWidth="250px"
                                    flexBasis="250px"
                                    flexGrow={0}
                                    flexShrink={0}
                                    p={isMobile ? '5px' : '10px'}
                                >
                                    <Stack alignItems="center" textAlign="center">
                                        <img src={value.icon} alt="" width="100%" />
                                        <Typography mt="20px" fontWeight={400} fontSize="16px" lineHeight="21px" color="#101828">
                                            {value.caption}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Grid>
            </Container>
        </Box>
    );
}

export default CompanyHighlights;
