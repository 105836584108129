import { Button, Typography, Grid, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

function StartupContent(props) {
    const { illustration, desc, descHeader } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Grid container justifyContent={isMobile ? 'center' : 'space-between'} alignItems="center">
            <Grid item order={isMobile ? 2 : 1} mt={isMobile && 5}>
                <img src={illustration} alt="illus" width={isMobile ? '100%' : '100%'} height={isMobile ? '100%' : '80%'} />
            </Grid>
            <Grid sx={{ maxWidth: `${isMobile ? '312px' : '50%'}` }} item order={isMobile ? 1 : 2} textAlign={isMobile ? 'center' : 'letf'}>
                <Typography fontWeight="700" fontSize="32px" color="#1B8AED">
                    {descHeader}
                </Typography>
                <Typography
                    marginTop="20px"
                    marginBottom="20px"
                    lineHeight="30px"
                    fontWeight="400px"
                    color="#3F3F3F"
                    fontSize={isMobile ? '14px' : '16px'}
                >
                    {desc}
                </Typography>
                <Button
                    variant="contained"
                    fullWidth={isMobile && true}
                    sx={{
                        backgroundColor: '#1B8AED',
                        marginTop: '12px',
                        padding: '15px 20px',
                        fontSize: '14px',
                        fontWeight: '700',
                        width: `${!isMobile && '430px'}`,
                        height: `${isMobile ? '45px' : '48px'}`,
                        color: '#fff',
                        borderRadius: '7px',
                        textTransform: 'none',
                        '&:hover': { backgroundColor: '#1B8AED' }
                    }}
                    disableElevation
                >
                    Learn more
                </Button>
            </Grid>
        </Grid>
    );
}

StartupContent.propTypes = {
    descHeader: PropTypes.any,
    illustration: PropTypes.string,
    desc: PropTypes.string
};

export default StartupContent;
