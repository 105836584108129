/* eslint-disable */

import {
  Box,
  Divider,
  Grid,
  useMediaQuery,
  Stack,
  Typography,
  Button,
  Container
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import StartupStudio from "./StartupStudio";
import programImage from "../../../../assets/images/aboutUs/part-four-prog-img.svg";

function ProgramTabs() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
 

  return (
    <Container>
      <Grid container mt={5} alignItems="center" justifyContent="center">
        <Grid item>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
            alignItems="center"
          >
            <Grid mb={"30px"}>
              <img src={programImage} alt="" />
            </Grid>
            <Typography
              fontSize="16px"
              fontWeight="400"
              width="60%"
              textAlign="center"
            >
              Semicolon Ventures is a venture builder solving local and
              global problems using a technology as to tool to scale
              market-creating innovation. We co-build start-ups with
              exciting entrepreneurs and develop solutions through
              co-innovation which address our corporate partner’s problems.
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <StartupStudio />
        </Grid>
      </Grid>
    </Container>
  );
}

export default ProgramTabs;
