/* eslint-disable */

import JaenPierre from 'assets/images/aboutUs/JaenPierreChoulet.svg';
import JoeAbah from 'assets/images/aboutUs/JoeAbah.svg';
import TommyDavis from 'assets/images/aboutUs/TommyDavis.svg';
import Twitter from 'assets/icons/Twitter.svg';
import LinkedIn from 'assets/icons/LinkedIn.svg';
import { useTheme } from '@mui/material/styles';
import { Box, Divider, Grid, useMediaQuery, Stack, Typography, Button, Container } from '@mui/material';

const contents = [
  {
    name: 'Joe Abah',
    img: JoeAbah,
    icon2: LinkedIn,
    link: 'https://www.linkedin.com/in/drjoeabah/'
  },
  {
    name: 'Tomi Davies',
    img: TommyDavis,
    icon2: LinkedIn,
    link: 'https://www.linkedin.com/in/tomidee/'
  },
  {
    name: 'Jean-Pierre Choulet',
    img: JaenPierre,
    icon2: LinkedIn,
    link: 'https://www.linkedin.com/in/jeanpierrechoulet/'
  }
];

function BoardOfAdvisors() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid mt={isMobile && 20} container py={5} alignItems="cenSter" direction="column" sx={{ backgroundColor: '#012341' }}>
      <Container>
        <Grid
          container
          position="relative"
          py={isMobile ? '10px' : 2}
          paddingX={isMobile ? '10px' : ''}
          alignItems="center"
          textAlign="center"
          direction="column"
        >
          <Typography color="#fff" fontWeight={700} fontSize="32px">
            Board of Advisors
          </Typography>
          {!isMobile && (
            <Divider
              data-aos="fade-right"
              data-aos-duration="4000"
              data-aos-once="false"
              data-aos-easing="ease-in-out"
              style={{
                position: 'absolute',
                width: '100%',
                bottom: '55%',
                zIndex: '-1'
              }}
              color="#1B8AED"
            />
          )}
          <Grid
            container
            mb={5}
            justifyContent={isMobile ? 'center' : 'space-between'}
            mt={5}
            alignItems="center"
            direction={isMobile ? 'column' : 'row'}
          >
            {contents.map((value, index) => (
              <Grid
                item
                width={isMobile ? '80%' : '30%'}
                backgroundColor="#FFFFFF"
                borderRadius="25px"
                boxShadow="6px 9px 9px rgba(0, 0, 0, 0.09)"
                p={5}
                mt={isMobile && 5}
                key={index}
              >
                <Stack direction="column" spacing="12px" alignItems="center">
                  <img src={value.img} alt="" width={isMobile ? '100px' : '100%'} />
                  <Typography color="#1B8AED" fontWeight={700} fontSize={15}>
                    {value.name}
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <a href={value.link}>
                      <img src={value.icon2} alt="" />
                    </a>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}

export default BoardOfAdvisors;
