import { Box, Typography, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

function ProcessContent({ icon, text, heading }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Box
            backgroundColor="white"
            maxWidth={isMobile ? '100%' : '300px'}
            mb="40px"
            padding="20px 10px"
            boxShadow="3px 4px 20px rgba(0, 0, 0, 0.1);"
            borderRadius="12px"
            textAlign="left"
        >
            <Box>
                <img src={icon} alt="contentIcon" width={isMobile ? '100%' : '100%'} height={isMobile ? '40%' : '200px'} />
            </Box>
            <Typography fontSize="24px" mt="20px" color="#1B8AED" fontWeight="600">
                {heading}
            </Typography>
            <Typography marginTop="10px" color="#667085">
                {text}
            </Typography>
        </Box>
    );
}
ProcessContent.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.string,
    heading: PropTypes.any
};
export default ProcessContent;
