import * as React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardMedia, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function StartupCard({ title, description, image }) {
    const [bcolor, setbcolor] = React.useState('rgba(0, 0, 0, 0.1)');

    const handleExpandClick = () => {
        setbcolor('rgba(27, 138, 237, 1)');
    };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Card
            sx={{
                width: `${isMobile ? '100%' : '33%'}`,
                marginX: '0',
                maxHeight: `${isMobile ? '' : '500px'}`,
                border: `1px solid ${bcolor}`,
                borderRadius: '12px'
            }}
            onMouseEnter={handleExpandClick}
            onMouseLeave={handleExpandClick}
            elevation={0}
        >
            <CardMedia sx={{ borderRadius: '6px' }} component="img" height="" width="100%" image={image} alt="growth" />
            <CardContent>
                <Typography fontWeight={700} fontSize="24px" color="#1F1F1F">
                    {title}
                </Typography>
            </CardContent>
            <CardContent>
                <Typography paragraph> {description}</Typography>
            </CardContent>
        </Card>
    );
}

StartupCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string
};

export default StartupCard;
