/* eslint-disable */
import { Box, Grid, Stack, Typography, useMediaQuery, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useState } from 'react';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const DirectImpacts = [
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657816395/semicolon%20ventures/Featured_icon_5_qbztiv.png',
        title: 'Social finance',
        caption: 'Enabling more inclusive finance by creating integration infrastructure across financial services',
        color: '#1B8AED',
        backgroundColor: 'rgba(27, 138, 237, 0.05)'
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657816324/semicolon%20ventures/Group_48096874_rvdany.png',
        title: 'Wellness',
        caption: 'Transforming mental wellbeing through social connections, recreation and wellness support',
        color: '#FF7E1D',
        backgroundColor: 'rgba(255, 126, 29, 0.05)'
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657816324/semicolon%20ventures/Featured_icon_1_gy6brw.png',
        title: 'Healthcare',
        caption: 'Improving access to healthcare and health outcomes by coordinating care delivery across patients, providers and payers',
        color: '#1B8AED',
        backgroundColor: 'rgba(27, 138, 237, 0.05)'
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657816324/semicolon%20ventures/Featured_icon_2_tawvw3.png',
        title: 'Cultural Heritage',
        caption: 'Promoting cultural awareness and integration by telling stories of cultural heritage',
        color: '#FF7E1D',
        backgroundColor: 'rgba(255, 126, 29, 0.05)'
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657816324/semicolon%20ventures/Featured_icon_3_mbnr2v.png',
        title: 'Mobility',
        caption: 'Enhancing mobility by providing real time transportation information across land, sea and air travel',
        color: '#1B8AED',
        backgroundColor: 'rgba(27, 138, 237, 0.05)'
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657816324/semicolon%20ventures/Featured_icon_4_evzgna.png',
        title: 'Financial integration',
        caption: 'Increasing upward social mobility through social finance by encouraging savings, investment and insurance',
        color: '#FF7E1D',
        backgroundColor: 'rgba(255, 126, 29, 0.05)'
    }
];

function DirectImpact() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const contents = DirectImpacts;
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = contents.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Box paddingY={isMobile ? '10px' : 10} sx={{ backgroundColor: '#F6F6F6' }}>
            <Container>
                <Grid
                    container
                    my={isMobile && 5}
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    gap={8}
                >
                    <Grid item textAlign="center" maxWidth={isMobile ? '320px' : '100%'}>
                        <Typography lineHeight="40px" letterSpacing="-4" fontWeight={700} color="#101828" fontSize={isMobile ? '24px' : '32px'}>
                            Direct Impact - Local problems solved with local solutions
                        </Typography>
                    </Grid>
                    <Grid item>
                        {
                            isMobile ? (
                                <Grid sx={{ maxWidth: '90vw' }} padding={isMobile ? '10px' : 10}>
                                    <AutoPlaySwipeableViews
                                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                        index={activeStep}
                                        onChangeIndex={handleStepChange}
                                        enableMouseEvents
                                    >
                                        {contents.map((value, index) => (
                                            <div key={index}>
                                                {Math.abs(activeStep - index) <= 2 ? (
                                                    <Grid container alignItems="center" justifyContent="center">
                                                        <Stack
                                                            backgroundColor={value.backgroundColor}
                                                            borderRadius="50%"
                                                            width="300px"
                                                            height="300px"
                                                            p="20px"
                                                            alignItems="center"
                                                            textAlign="center"
                                                            justifyContent="center"
                                                        >
                                                            <img src={value.icon} alt="" width="70px" height="70px" />
                                                            <Typography mt="34px" width="238px" fontWeight={600} fontSize={20} color={value.color}>
                                                                {value.title}
                                                            </Typography>
                                                            <Typography mt="20px" width="238px" fontWeight={400} fontSize="14px" lineHeight="21px" color={value.color}>
                                                                {value.caption}
                                                            </Typography>
                                                        </Stack>
                                                    </Grid>
                                                ) : null}
                                            </div>
                                        ))}
                                    </AutoPlaySwipeableViews>
                                </Grid>
                            ) : (
                                <Grid container spacing={isMobile ? '10px' : 5} alignItems="center" justifyContent={isMobile ? 'center' : 'space-between'}>
                                    {DirectImpacts.map((value, index) => (
                                        <Grid container key={index} alignItems="center" justifyContent={isMobile ? 'center' : 'space-between'} item lg={4} xl={4} md={4} xs={12} sm={12}>
                                            <Stack
                                                backgroundColor={value.backgroundColor}
                                                borderRadius="50%"
                                                maxWidth="250px"
                                                height="250px"
                                                p={isMobile ? '5px' : '30px'}
                                                alignItems="center"
                                                textAlign="center"
                                            >
                                                <img src={value.icon} alt="" width="70px" height="70px" />
                                                <Typography mt="34px" fontWeight={600} fontSize={20} color={value.color}>
                                                    {value.title}
                                                </Typography>
                                                <Typography mt="20px" fontWeight={400} fontSize="14px" lineHeight="21px" color={value.color}>
                                                    {value.caption}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    ))}
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default DirectImpact;
