/* eslint-disable */
import { Box, Grid, Stack, Typography, useMediaQuery, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useState } from 'react';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const SystemChangeImpacts = [
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657821455/semicolon%20ventures/Featured_icon_6_djx1oc.png',
        title: 'Support for all',
        caption: 'Support people without backgrounds of privilege and access to create solutions',
        color: '#009FF5',
        backgroundColor: 'rgba(0, 159, 245, 0.05)',
        captionColor: 'rgba(0, 159, 245, 0.72)'
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657821455/semicolon%20ventures/Featured_icon_7_c8yy1i.png',
        title: 'Problem solving',
        caption: 'Democratize problem solving and who can be a successful founder',
        color: '#F75500',
        backgroundColor: 'rgba(247, 85, 0, 0.05)',
        captionColor: 'rgba(247, 85, 0, 0.72)'
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657821455/semicolon%20ventures/Featured_icon_8_eqjcjz.png',
        title: 'Efficient building',
        caption: 'Build more efficiency into the early stage angel and VC market',
        color: '#00D689',
        backgroundColor: 'rgba(0, 214, 137, 0.05)',
        captionColor: 'rgba(0, 214, 137, 0.72)'
    },
    {
        icon: 'https://res.cloudinary.com/omimi/image/upload/v1657821455/semicolon%20ventures/Featured_icon_9_pioze2.png',
        title: 'Skill provision',
        caption: 'Ventures are oriented to solve real problems, equipped with the right skills and given the critical early stage support',
        color: '#2A2B4B',
        backgroundColor: 'rgba(55, 53, 91, 0.05)',
        captionColor: 'rgba(59, 57, 95, 0.72)'
    }
];

function SystemChangeImpact() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const contents = SystemChangeImpacts;
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = contents.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Box paddingY={isMobile ? '10px' : 10} sx={{ backgroundColor: '#F6F6F6' }}>
            <Container>
                <Grid
                    container
                    my={isMobile && 5}
                    justifyContent="center"
                    alignItems={isMobile ? 'center' : 'left'}
                    direction="column"
                    gap={8}
                >
                    <Grid item textAlign={isMobile ? 'center': 'left'} maxWidth={isMobile ? '320px' : '100%'}>
                        <Typography lineHeight="40px" letterSpacing="-4" fontWeight={700} color="#101828" fontSize={isMobile ? '24px' : '32px'}>
                            Systems Change Impact
                        </Typography>
                    </Grid>
                    <Grid item>
                        {
                            isMobile ? (
                                <Grid sx={{ maxWidth: '90vw' }} padding={isMobile ? '10px' : 10}>
                                    <AutoPlaySwipeableViews
                                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                        index={activeStep}
                                        onChangeIndex={handleStepChange}
                                        enableMouseEvents
                                    >
                                        {contents.map((value, index) => (
                                            <div key={index}>
                                                {Math.abs(activeStep - index) <= 2 ? (
                                                    <Grid container key={index} alignItems="center" justifyContent={isMobile ? 'center' : 'space-between'} item lg={3} xl={3} md={3} xs={12} sm={12}>
                                                        <Stack
                                                            backgroundColor={value.backgroundColor}
                                                            borderRadius="8px"
                                                            maxWidth="250px"
                                                            minHeight="300px"
                                                            p="15px"
                                                            alignItems="left"
                                                            textAlign="left"
                                                        >
                                                            <img src={value.icon} alt="" width="50px" height="50px" />
                                                            <Typography mt="96px" fontWeight={500} fontSize={20} color={value.color}>
                                                                {value.title}
                                                            </Typography>
                                                            <Typography mt="20px" fontWeight={400} fontSize="14px" lineHeight="21px" color={value.captionColor}>
                                                                {value.caption}
                                                            </Typography>
                                                        </Stack>
                                                    </Grid>
                                                ) : null}
                                            </div>
                                        ))}
                                    </AutoPlaySwipeableViews>
                                </Grid>
                            ) : (
                                <Grid container spacing={isMobile ? '10px' : 5} alignItems="center" justifyContent={isMobile ? 'center' : 'space-between'}>
                                    {SystemChangeImpacts.map((value, index) => (
                                        <Grid container key={index} alignItems="center" justifyContent={isMobile ? 'center' : 'space-between'} item lg={3} xl={3} md={3} xs={12} sm={12}>
                                            <Stack
                                                backgroundColor={value.backgroundColor}
                                                borderRadius="8px"
                                                maxWidth="250px"
                                                minHeight="300px"
                                                p={isMobile ? '5px' : '15px'}
                                                alignItems="left"
                                                textAlign="left"
                                            >
                                                <img src={value.icon} alt="" width="50px" height="50px" />
                                                <Typography mt="96px" fontWeight={600} fontSize={20} color={value.color}>
                                                    {value.title}
                                                </Typography>
                                                <Typography mt="20px" fontWeight={400} fontSize="14px" lineHeight="21px" color={value.captionColor}>
                                                    {value.caption}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    ))}
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default SystemChangeImpact;
