/* eslint-disable */
import { Button, Box, Grid, Typography, Stack, useMediaQuery, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function AboutTheFund() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    return (
        <Container>
            <Grid
                container
                justifyContent={isMobile ? 'center' : 'space-between'}
                paddingY={isMobile ? 10 : 20}
                paddingX={isMobile ? '10px' : '2%'}
                alignItems="center"
                textAlign={isMobile ? 'center' : 'left'}
                backgroundColor={isMobile ? '#FFFFFF' : '#F9FAFB'}
            >
                <Grid mt={isMobile && 4} item>
                    <Box alignItems={isMobile && 'center'} textAlign={isMobile ? 'center' : 'left'}>
                        <Typography mb={2} lineHeight="40px" fontWeight={600} color="#101828" fontSize={isMobile ? '24px' : '32px'}>
                            About the fund
                        </Typography>
                        <img src="https://res.cloudinary.com/omimi/image/upload/v1655118776/blue-underline-Vector_fvdqoo.svg" alt="underline" />
                        <Stack spacing="30px" mt="20px" alignItems={isMobile && 'center'} mb={isMobile && 10}>
                            <Typography
                                width={isMobile ? '100%' : '418px'}
                                lineHeight="30px"
                                fontWeight="400"
                                color="#3F3F3F"
                                fontSize={isMobile ? '16px' : '18px'}
                            >
                                The Growth fund is set up to deploy pre-seed stage capital to support start-ups in our portfolio. The fund aims
                                to address the lack of access to early-stage funding for startups on the continent.
                                <br />
                                We have chosen to invest solely in start-up’s backed by trained software engineers with innovative ideas capable
                                of serving a large target market, increasing the chance of success and follow on Venture capital investments. We
                                expect most of our portfolio companies to reach growth stage and provide exit opportunities within 5-years.
                            </Typography>
                            {isMobile && (
                                <Button
                                    sx={{
                                        backgroundColor: '#1B8AED',
                                        borderRadius: '8px',
                                        width: isMobile ? '100%' : '300px',
                                        height: `${isMobile ? '45px' : '48px'}`,
                                        fontSize: '16px',
                                        padding: '12px 32px',
                                        textTransform: 'none',
                                        fontWeight: '600'
                                    }}
                                    variant="contained"
                                >
                                    Get in touch
                                </Button>
                            )}
                        </Stack>
                    </Box>
                </Grid>
                <Grid item display={!isMobile && 'flex'} justifyContent={!isMobile && 'right'}>
                    <img
                        src="https://res.cloudinary.com/omimi/image/upload/v1657719836/semicolon%20ventures/Group_48096840_i5qgwu.png"
                        alt=""
                        width={isMobile ? '100%' : '80%'}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

export default AboutTheFund;
