/* eslint-disable */
import { Box, Grid, Typography, Stack, useMediaQuery, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const partners = [
    'https://res.cloudinary.com/omimi/image/upload/v1657531709/semicolon%20ventures/photo_2021-08-28_12-46-54_1_snxygz.png',
    'https://res.cloudinary.com/omimi/image/upload/v1657531709/semicolon%20ventures/Frame_48097262_kol1a4.png',
    'https://res.cloudinary.com/omimi/image/upload/v1657531708/semicolon%20ventures/Frame_48097275_nnni2e.png',
    'https://res.cloudinary.com/omimi/image/upload/v1657531708/semicolon%20ventures/Frame_48097277_f4f0we.png'
];

function Partners() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Container>
            <Grid
                container
                my={isMobile && 20}
                paddingY={isMobile ? '10px' : 10}
                justifyContent="center"
                alignItems="left"
                textAlign="left"
                gap={4}
                backgroundColor="#F6F6F6"
            >
                <Grid item textAlign="left">
                    <Typography lineHeight="40px" letterSpacing="-4" fontWeight={700} color="#101828" fontSize="32px">
                        Partners
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container spacing={isMobile ? '10px' : 5} justifyContent="center" alignItems="top">
                        {partners.map((value, index) => (
                            <Grid key={index} item lg={3} xl={3} md={4} xs={12} sm={12}>
                                <img src={value} alt="partner" width="100%" />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Partners;
