/* eslint-disable */
import { Typography, Stack, Grid, Box, useMediaQuery, Container } from '@mui/material';
// import LinePattern from 'assets/images/founders/LinePattern.svg';
import { useTheme } from '@mui/material/styles';

function CTA() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
   

    return (
        <Box paddingY={isMobile ? 0 : 5} sx={{ backgroundColor: '#fff' }}>
            <Container>
                <Grid
                    container
                    justifyContent={isMobile ? 'center' : 'space-between'}
                    paddingX={isMobile ? '10px' : '2%'}
                    paddingY={isMobile ? 10 : 5}
                    direction={isMobile ? 'column' : 'row'}
                    alignItems="center"
                    textAlign={isMobile ? 'center' : 'left'}
                    backgroundColor="rgba(255, 174, 79, 0.05)"
                    borderRadius={isMobile ? 0 : '8px'}
                >
                    <Grid maxWidth={isMobile ? '100%' : '45%'} mt={isMobile && 4} item>
                        <Box alignItems={isMobile && 'center'} textAlign={isMobile ? 'center' : 'left'}>
                            <Typography mb={2} lineHeight="40px" fontWeight={600} color="#101828" fontSize={isMobile ? '24px' : '32px'}>
                                About the Founders
                            </Typography>
                            <img
                                src="https://res.cloudinary.com/omimi/image/upload/v1655071886/semicolon%20ventures/underlineVector_zdsoej.svg"
                                alt="underline"
                            />
                            <Stack spacing="30px" mt="20px" alignItems={isMobile && 'center'} mb={isMobile && 10}>
                                <Typography lineHeight="30px" fontWeight="400" color="#3F3F3F" fontSize={isMobile ? '16px' : '18px'}>
                                    Our founders are ambitious entrepreneurs, building companies that solve local and global problems in
                                    innovative ways, while creating increased economic inclusion and employment opportunities resulting in
                                    economic growth.
                                    <br />
                                    Founders in our portfolio go through a 1-year intensive Software Engineering program that equips them
                                    with problem-solving, technical and business management skills which essentially prepares them for the
                                    digital economy and gives them an edge on their entrepreneurial journey.
                                </Typography>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid display={!isMobile && 'flex'} justifyContent={!isMobile && 'right'} item>
                        <img
                            src="https://res.cloudinary.com/omimi/image/upload/v1655134140/Vent11_1_zrqemm.png"
                            alt="unsplash"
                            width={isMobile ? '100%' : '80%'}
                            height={isMobile ? '100%' : '80%'}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default CTA;
