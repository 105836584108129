import * as React from 'react';
import { Card, CardMedia, CardContent, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

function ActivityCard({ title, description, image }) {
    const theme = useTheme();
    const [bcolor, setbcolor] = React.useState('rgba(0, 0, 0, 0.1)');
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const handleExpandClick = () => {
        setbcolor('rgba(27, 138, 237, 1)');
    };

    return (
        <Card
            sx={{
                maxWidth: `${isMobile ? '320px' : '369px'}`,
                height: '442px',
                border: `1px solid ${bcolor}`,
                borderRadius: '12px'
            }}
            onMouseEnter={handleExpandClick}
            onMouseLeave={handleExpandClick}
            elevation={0}
        >
            <CardMedia sx={{ borderRadius: '6px' }} component="img" width="320px" height="230px" image={image} alt="growth" />
            <CardContent>
                <Typography fontWeight={700} fontSize="24px" color="#404A6D">
                    {title}
                </Typography>
            </CardContent>
            <CardContent>
                <Typography fontSize="16px" paragraph>
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
}

ActivityCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string
};

export default ActivityCard;
