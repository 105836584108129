/* eslint-disable */
import { Box, Grid, Stack, Typography, useMediaQuery, Container } from '@mui/material';
import Cpu from 'assets/icons/ourService/Cpu.svg';
import Package from 'assets/icons/ourService/Package.svg';
import Cloud from 'assets/icons/ourService/Cloud.svg';
import Award from 'assets/icons/ourService/Award.svg';
import Gift from 'assets/icons/ourService/Gift.svg';
import Legal from 'assets/icons/ourService/Anchor.svg';
import User from 'assets/icons/ourService/Users.svg';
import Trello from 'assets/icons/ourService/Trello.svg';
import UserPlus from 'assets/icons/ourService/UserPlus.svg';
import Map from 'assets/icons/ourService/Map.svg';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

const servicesProvided = [
    {
        icon: Cpu,
        text: 'Technical Team'
    },
    {
        icon: Package,
        text: 'Operational Support'
    },
    {
        icon: Cloud,
        text: 'Cloud hosting Service'
    },
    {
        icon: Award,
        text: 'Partnership'
    },
    {
        icon: Gift,
        text: 'Funding'
    },
    {
        icon: Legal,
        text: 'Legal Support'
    },
    {
        icon: User,
        text: 'Board Participation'
    },
    {
        icon: Trello,
        text: 'Research and Analytics'
    },
    {
        icon: UserPlus,
        text: 'Network and Community'
    },
    {
        icon: Map,
        text: 'Workspace'
    }
];

function ServicesProvided() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
   

    return (
        <Box sx={{background: 'linear-gradient(4.31deg, rgba(151, 201, 247, 0.4) -195.03%, rgba(255, 255, 255, 0.4) 128.72%)' }}>
            <Container>
                <Grid
                    container
                    mt={isMobile && 20}
                    justifyContent="center"
                    alignItems="center"
                    gap={4}
                    pb={!isMobile && 5}
                >
                    <Grid item textAlign="center">
                        <Stack direction="column" alignItems="center" textAlign="center" justifyContent="center">
                            <Typography lineHeight="40px" letterSpacing="-4" fontWeight={700} color="#101828" fontSize="32px">
                                The Services We Provide
                            </Typography>
                            <Box>
                                <img
                                    src="https://res.cloudinary.com/omimi/image/upload/v1655071886/semicolon%20ventures/underlineVector_zdsoej.svg"
                                    alt="underline"
                                />
                            </Box>
                            <Typography
                                my="20px"
                                width={isMobile ? '200px' : '478px'}
                                lineHeight="30px"
                                fontWeight={400}
                                color="#000"
                                fontSize={isMobile ? '14px' : '20px'}
                            >
                                We provide a range of early-stage business support which include but not limited to:
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Grid container gap={isMobile ? '10px' : 5} justifyContent="center">
                            {servicesProvided.map((value, index) => (
                                <Grid key={index} item lg={2.4} xl={2.4} md={2.4} xs={12} sm={12}>
                                    <Stack
                                        backgroundColor="#FFFFFF"
                                        boxShadow="3px 4px 20px rgba(0, 0, 0, 0.1)"
                                        borderRadius="12px"
                                        height="200px"
                                        justifyContent="flex-start"
                                        py="30px"
                                        alignItems="center"
                                        textAlign="center"
                                    >
                                        <img src={value.icon} alt="" width="70px" height="70px" />
                                        <Typography textAlign="center" maxWidth="80%" mt="34px" fontWeight={600} fontSize={20} color="#101828">
                                            {value.text}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default ServicesProvided;
