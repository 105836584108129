/* eslint-disable */
import bellImg from "../../../../assets/images/aboutUs/part-two-bell-img.svg";
import bellImgMobile from "../../../../assets/images/aboutUs/part-two-bell-mobile.svg";
import { Typography, Button, Stack, Grid, Box, useMediaQuery, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function WhoWeAre() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
 

  return (
    <Container>
      <Grid
        paddingY={isMobile ? '20px' : 5}>
        <Grid
          container
          justifyContent={isMobile ? 'center' : 'space-between'}
          paddingX={isMobile ? '10px' : 2}
          paddingY={isMobile ? 10 : 5}
          alignItems="center"
          textAlign={isMobile ? 'center' : 'left'}
          borderRadius={isMobile ? 0 : '8px'}
          direction={isMobile ? 'column' : 'row'}
          sx={{ backgroundColor: `${isMobile ? '#fff' : '#f7f7f7'}` }}
        >
          <Grid mt={isMobile && 4} item width={isMobile ? '100%' : '50%'}>
            <Box alignItems={isMobile && 'center'} textAlign={isMobile ? 'left' : 'left'}>
              <Typography mb={2} lineHeight="40px" fontWeight={700} color="#1B8AED" fontSize={isMobile ? '24px' : '32px'}>
                Who we are
              </Typography>
              <Stack spacing="30px" mt="20px" alignItems={isMobile && 'center'} mb='36px'>
                <Typography
                  lineHeight="30px"
                  fontWeight="400"
                  color="#3F3F3F"
                  fontSize={isMobile ? '16px' : '18px'}
                >
                  At Semicolon Ventures we invest in early-stage high-growth start-ups.
                  With our entrepreneurs we focus on delivering products that contribute
                  to the evolving digital economy landscape in Africa.
                  <br /> <br />
                  We nurture start-ups by developing our founders strong business ideas
                  into viable products by providing administrative, training, mentorship,
                  and financing support. Our intense venture building program aids in our portfolio companies' swift development and launch.
                </Typography>
                {isMobile && (
                  <Button
                    variant="contained"
                    fullWidth={isMobile && true}
                    sx={{
                      backgroundColor: '#1B8AED',
                      marginTop: '92px',
                      padding: '15px 20px',
                      fontSize: '14px',
                      fontWeight: '700',
                      width: `${!isMobile && '430px'}`,
                      height: `${isMobile ? '45px' : '48px'}`,
                      color: '#fff',
                      borderRadius: '7px',
                      textTransform: 'none',
                      '&:hover': { backgroundColor: '#1B8AED' }
                    }}
                    disableElevation
                  >
                    Learn More
                  </Button>
                )}
              </Stack>
            </Box>
          </Grid>
          <Grid backgroundColor="#f7f7f7" display={!isMobile && 'flex'} justifyContent={!isMobile && 'right'} item order={isMobile ? 2 : 1}>
            <img
              src={bellImgMobile}
              alt="bell"
              width={isMobile ? '100%' : '100%'}
              height={isMobile ? '100%' : '80%'}
            />
          </Grid>
        </Grid>
      </Grid >
    </Container>

  );
}

export default WhoWeAre;
