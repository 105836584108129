import { Stack, Typography, Grid, Box, useMediaQuery, Container } from '@mui/material';
import ProcessContent from './ProcessContent';
import CompanySetup from 'assets/icons/ProcessImageFolder/CompanySetup.svg';
import DemoDay from 'assets/icons/ProcessImageFolder/DemoDay.svg';
import PreSeed from 'assets/icons/ProcessImageFolder/PreSeed.svg';
import StartupStudio from 'assets/icons/ProcessImageFolder/StartupStudio.svg';
import TestAndRedeployment from 'assets/icons/ProcessImageFolder/TestAndRedeployment.svg';
import FurtherDevelopment from 'assets/icons/ProcessImageFolder/FurtherDevelopment.svg';
import Arrow from 'assets/illustrations/ourService/Arrow.svg';
import { useTheme } from '@mui/material/styles';

function Process() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Container>
            <Stack sx={{ backgroundColor: 'linear-gradient(180deg, #F1F8FE 0%, rgba(255, 255, 255, 0) 7.18%' }} mt={10} textAlign="center">
                <Grid item mb={10}>
                    <Stack textAlign="center" alignItems="center" justifyContent="center">
                        <img src="https://res.cloudinary.com/omimi/image/upload/v1655120479/the_process_ei8xkv.png" alt="the process" />
                        <Box width={isMobile ? '312px' : '638px'} mt={5}>
                            <Typography
                                fontWeight="400"
                                fontSize={isMobile ? '16px' : '18px'}
                                height="90px"
                                color="#667085"
                                maxWidth={isMobile ? '312px' : '600px'}
                            >
                                As founders ourselves with experience in building innovative technologies; we co-build with the founders
                                throughout their journey offering the necessary support and services.
                            </Typography>
                        </Box>
                    </Stack>
                </Grid>

                <Grid item container position="relative" justifyContent={isMobile ? 'center' : 'space-around'}>
                    {!isMobile && (
                        <Box position="absolute" zIndex={1} left="0" right="0" margin="auto" top="20%">
                            <img src={Arrow} alt="" width="20%" />
                        </Box>
                    )}
                    {!isMobile && (
                        <Box position="absolute" zIndex={1} left="0" right="0" margin="auto" top="45%">
                            <img src={Arrow} alt="" width="20%" />
                        </Box>
                    )}
                    {!isMobile && (
                        <Box position="absolute" zIndex={1} left="0" right="0" margin="auto" top="70%">
                            <img src={Arrow} alt="" width="20%" />
                        </Box>
                    )}
                    <Grid item>
                        <ProcessContent
                            heading="Startup Studio"
                            icon={StartupStudio}
                            text="3-Months workshop to develop their product ideas"
                        />
                        <ProcessContent
                            heading="Build"
                            icon="https://res.cloudinary.com/omimi/image/upload/v1655120751/build_illus_oxvrke.png"
                            text="Build, test and re-deploy product, provide technical, admin, mentorship and networking"
                        />
                        <ProcessContent heading="Test and Redeployment" icon={TestAndRedeployment} text="Product is tested and improved." />
                        <ProcessContent
                            heading="Investment Readiness"
                            icon={FurtherDevelopment}
                            text="Further re-development, pre-launch"
                        />
                    </Grid>
                    <Grid item pt={!isMobile && 10}>
                        <ProcessContent
                            heading="Company Setup"
                            icon={CompanySetup}
                            text="Register company, set-up bank account, founders agreement and milestones"
                        />

                        <ProcessContent heading="Pre-Seed" icon={PreSeed} text="Funds of up to $25k is provided to founders." />
                        <ProcessContent
                            heading="Demo day"
                            icon={DemoDay}
                            text="To showcase product to investors, investment committee and build traction."
                        />
                    </Grid>
                </Grid>
            </Stack>
        </Container>
    );
}
export default Process;
