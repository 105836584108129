import { Typography, Stack, useMediaQuery, Box, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function Hero() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Box
            width="100vw"
            sx={{
                backgroundImage: `url(https://res.cloudinary.com/omimi/image/upload/v1657528735/semicolon%20ventures/-default__1_keisuq.png)`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100vw 100vh'
            }}
        >
            <Container>
                <Stack textAlign="center" alignItems="center" justifyContent="center" height="100vh" pt={isMobile && 10}>
                    <Typography
                        textAlign="center"
                        fontWeight="700"
                        fontSize={`${isMobile ? 32 : 64}px`}
                        letterSpacing="-1%"
                        lineHeight="72px"
                        color="white"
                    >
                        The Nexus Project
                    </Typography>
                    <Typography
                        mt={`${isMobile ? 7 : 26}px`}
                        textAlign="center"
                        fontWeight="400"
                        fontSize={`${isMobile ? 18 : 24}px`}
                        lineHeight="30px"
                        color="white"
                        maxWidth={`${isMobile ? 302 : 558}px`}
                    >
                        Transforming job seekers to sustainable job creators
                    </Typography>
                </Stack>
            </Container>
        </Box>
    );
}

export default Hero;
