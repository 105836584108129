import { Typography, Stack, useMediaQuery, Button, Box, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function Hero() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Box
            width="100vw"
            sx={{
                backgroundImage: `url(${
                    isMobile
                        ? 'https://res.cloudinary.com/omimi/image/upload/v1657800844/semicolon%20ventures/home-hero-bg_4_ok4ddb.png'
                        : 'https://res.cloudinary.com/omimi/image/upload/v1657713864/semicolon%20ventures/home-hero-bg_3_bxk5et.png'
                })`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}
        >
            <Container>
                <Stack textAlign="center" alignItems="center" justifyContent="center" height="100vh" pt={isMobile && 10}>
                    <Typography
                        textAlign="center"
                        fontWeight="700"
                        fontSize={`${isMobile ? 32 : 64}px`}
                        letterSpacing="-1%"
                        lineHeight="72px"
                        color="white"
                    >
                        Building with love!
                    </Typography>
                    <Typography
                        mt={`${isMobile ? 7 : 26}px`}
                        textAlign="center"
                        fontWeight="400"
                        fontSize={`${isMobile ? 18 : 24}px`}
                        lineHeight="30px"
                        color="white"
                        maxWidth={`${isMobile ? 302 : 558}px`}
                    >
                        A venture builder of innovative technologies that solve local and global problems
                    </Typography>
                    {isMobile && (
                        <Button
                            variant="contained"
                            fullWidth={isMobile && true}
                            sx={{
                                backgroundColor: '#fff',
                                marginTop: '30vh',
                                padding: '15px 32px',
                                fontSize: '14px',
                                fontWeight: '700',
                                height: `${isMobile ? '45px' : '48px'}`,
                                width: `${!isMobile && '148px'}`,
                                color: '#fff',
                                borderRadius: '7px',
                                textTransform: 'none',
                                '&:hover': { backgroundColor: '#1B8AED' }
                            }}
                            disableElevation
                        >
                            <Typography fontSize="16px" color="#1B8AED" lineHeight="24px" fontWeight={700}>
                                Get in touch
                            </Typography>
                        </Button>
                    )}
                </Stack>
            </Container>
        </Box>
    );
}

export default Hero;
