import FooterBody from './FooterBody';

function Footer() {
    return (
        <div>
            <FooterBody />
        </div>
    );
}

export default Footer;
