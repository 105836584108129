import { Container, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function NexusVideo() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Container>
            <iframe
                width="100%"
                height={isMobile ? '250px' : '615px'}
                src="https://www.youtube.com/embed/tt70EfZTnxI?modestbranding=1&showinfo=0&end=280&rel=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </Container>
    );
}
