const content = [
    {
        title: 'Onhova',
        image: 'https://res.cloudinary.com/omimi/image/upload/v1655129486/Vent8_1_momgda.png',
        description: `Onhova is focusing on how to enhance the wholesale and retail distribution network through its digital procurement
                        platform which enables price discovery, inventory visibility and seamless stock replenishment.`
    },
    {
        title: 'Oneplug',
        image: 'https://res.cloudinary.com/omimi/image/upload/v1657711111/semicolon%20ventures/Vent14_2_zbhqo9.png',
        description: `Oneplug is building a financial integration infrastructure,
                    implementing channels available through the advent of open
                    banking to enable interaction and visibility across the financial services industry. `
    },
    {
        title: 'Goodtime',
        image: 'https://res.cloudinary.com/omimi/image/upload/v1655129438/Vent7_1_old58z.png',
        description: `Goodtime is a community curated for users and businesses that match-makes people based on their recreational interests`
    }
];

export default content;
