/* eslint-disable */
import { Grid, Typography, Button, Stack, Box, useMediaQuery, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

function ServicesLnR({ title, description, description1, image, color, button, position }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    return (
        <Container>
            <Grid
                container
                justifyContent={isMobile ? 'center' : 'space-between'}
                paddingY={isMobile ? 5 : 10}
                paddingX={isMobile ? '10px' : '2%'}
                alignItems="center"
                textAlign={isMobile ? 'center' : 'left'}
            >
                <Grid order={isMobile || position === 'left' ? 1 : 2} item display={!isMobile && 'flex'} maxWidth={isMobile ? '' : '50%'} justifyContent={!isMobile && position}>
                    {typeof image !== 'string' ? (
                        <div width={isMobile ? '100%' : '80%'}>{image}</div>
                    ) : (
                        <img src={image} alt="growth" width={isMobile ? '100%' : '100%'} />
                    )}
                </Grid>
                <Grid order={isMobile || position === 'left' ? 2 : 1} mt={isMobile && 4} item maxWidth={isMobile ? '328px' : '40%'}>
                    <Box alignItems={isMobile && 'center'} textAlign={isMobile ? 'center' : 'left'}>
                        <Typography mb={2} lineHeight="40px" fontWeight={600} color="#101828" fontSize={isMobile ? '24px' : '32px'}>
                            {title}
                        </Typography>
                        <img src="https://res.cloudinary.com/omimi/image/upload/v1655118776/blue-underline-Vector_fvdqoo.svg" alt="underline" />
                        <Stack spacing="30px" mt="20px" alignItems={isMobile && 'center'} mb={isMobile && 10}>
                            <Typography
                                lineHeight="30px"
                                fontWeight="400"
                                color="#3F3F3F"
                                fontSize={isMobile ? '16px' : '18px'}
                            >
                                {description}
                            </Typography>
                            {description1 && (
                                <>
                                    <br />
                                    <Typography
                                        lineHeight="30px"
                                        fontWeight="400"
                                        color="#3F3F3F"
                                        fontSize={isMobile ? '16px' : '18px'}
                                    >
                                        {description1}
                                    </Typography>
                                </>
                            )}
                            {button && (
                                <Button
                                    variant="contained"
                                    // width={!isMobile && '430px'}
                                    fullWidth={isMobile && true}
                                    sx={{
                                        backgroundColor: '#1B8AED',
                                        marginTop: '92px',
                                        padding: '15px 20px',
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        width: `${!isMobile && '45%'}`,
                                        height: `${isMobile ? '45px' : '48px'}`,
                                        color: '#fff',
                                        borderRadius: '7px',
                                        textTransform: 'none',
                                        '&:hover': { backgroundColor: '#1B8AED' }
                                    }}
                                    disableElevation
                                >
                                    Get in touch
                                </Button>
                            )}
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}
ServicesLnR.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    position: PropTypes.string,
    description: PropTypes.string,
    button: PropTypes.bool,
    image: PropTypes.any
};

export default ServicesLnR;
